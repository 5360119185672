import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-pagina-final',
  templateUrl: './pagina-final.component.html',
  styleUrls: ['./pagina-final.component.scss']
})
export class PaginaFinalComponent implements OnInit {

  options: any;
  overlays: any[];
  constructor() { }

  ngOnInit() {
    this.options = {
      center: {lat: -22.887443, lng: -43.228189},
      zoom: 12
  };
  }

}
