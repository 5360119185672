import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {

  public tituloSite = 'JL TELECOM - (21) 3890-1846';


  constructor( ){      
  }

  public ngOnInit() {

    }
}
