
<div class="row box-conteudo box-card pb-4">
	<div class="col-12" role="autor">
		<div class="row">
			<div class="col-12 item-title">
				<span class="subtitulo sub-title-jl ">A JL Telecom</span>
				<i class="fa fa-question-circle-o icon-help"
					title="Informações sobre" role="button"
					aria-label="Informações sobre personagens, para ativar use a tecla ENTER"
					>
				</i>
				<hr>
			</div>
		</div>

		<div>
            <div class="row">
            	<div class="col-12">
					<p class="texto-sobre">Uma empresa voltada ao segmento de telecomunicações,  trabalha com todo tipo de soluções de internet, desde banda larga residencial a empresarial. A empresa possui autorga da Anatel sendo autorizada para trabalhar com serviços de comunicação multimídia em todo território nacional, podendo assim apresentar soluções de interconexões e serviços.
						A empresa está sempre em contato com seus clientes a fim de obter feedback e opiniões para melhoria nos serviços. Possuímos uma equipe qualificada e especializada no que faz para melhor atender aos nosso clientes. A filosofia de trabalho da nossa empresa supera o objetivo de simplesmente oferecer conexão aos clientes, mas sim para satisfazer as suas necessidades.</p>
				</div>
			</div>
		</div>
	</div>
</div>