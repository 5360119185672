import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagina-planos',
  templateUrl: './pagina-planos.component.html',
  styleUrls: ['./pagina-planos.component.scss']
})
export class PaginaPlanosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
