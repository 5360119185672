<div class="row ">
	<div class="col-12 ">
		<div id="carouselExampleIndicators" class="carousel slide " data-bs-ride="carousel">
			<div class="carousel-indicators">
				<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
					aria-current="true" aria-label="Slide 1"></button>
				<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
					aria-label="Slide 2"></button>
				<button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
					aria-label="Slide 3"></button>
			</div>
			<div class="carousel-inner">
				<div class="carousel-item active">
					<img class="d-block w-100"
						src="../../../assets/img/sl33.jpg?auto=yes&bg=555&fg=333&text=Primeiro Slide"
						alt="primeiro Slide">
				</div>
				<div class="carousel-item ">
					<img class="d-block w-100"
						src="../../../assets/img/sl2.jpg?auto=yes&bg=555&fg=333&text=Segundo Slide" alt="Segundo Slide">
				</div>
				<div class="carousel-item">
					<img class="d-block w-100"
						src="../../../assets/img/entre.jpg?auto=yes&bg=555&fg=333&text=quinto Slide" alt="Quinto Slide">
				</div>
			</div>
			<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
				data-bs-slide="prev">
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Anterior</span>
			</button>
			<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
				data-bs-slide="next">
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Próximo</span>
			</button>
		</div>
	</div>
</div>

<div class="row mt-3">
	<div class="outer-container clearfix">
		<div style="display: flex; justify-content: center;">
			<div style="padding: 0px 50px;">
				<div class="sec-title-five">
					<h2>Chegou o aplicativo JL Telecom</h2>
				</div>
				<div class="text">
					<p>Baixe agora mesmo o aplicativo da JL Telecom e gerencie seu plano com mais facilidade.</p>
				</div>
				
				<div style="text-align: left;">
					<!-- <a href="https://apps.apple.com/br/app/jl/id1556036675?itsct=apps_box_link&amp;itscg=30200" target="_blank" style="display: inline-block; margin: 15px 7px 15px 20px;">
						<img src="../../../assets/img/app-store-badge-black.svg" alt="Download on the App Store" style="height: 55px;">
					</a> -->
					<a href="https://play.google.com/store/apps/details?id=net.jltelecom.app" target="_blank" style="display: inline-block; margin: 15px 7px;">
						<img src="../../../assets/img/google-play-badge.png" alt="Disponível no Google Play" style="height: 80px;">
					</a>
				</div>
			</div>
			<div class="mobileHide">
				<figure class="image-box"><img src="../../../assets/img/mobile.png"></figure>
			</div>
		</div> 
	</div>
</div>



<div class="row mt-2">
	<div class="col-12 ">
		<div id="greywrap">
			<div class="row">
				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-signal" style="font-size:48px;color:blue"></i>
					<i class="fas fa-wifi fa-7x"></i>
					<h2>WI-FI</h2>
					<p> Acesso livre em computadores, notebooks, celulares e tablets. </p>
				</div><!-- col-lg-4 -->

				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-phone" style="font-size:48px;color:blue"></i>
					<i class="fas fa-mobile-alt fa-7x"></i>
					<h2>Internet nos dispositivos móveis</h2>
					<p>Alta qualidade de navegação com velocidade estável e segura.Tecnologia com Infra-estruturar em
						Fibra Óptica. </p>
				</div><!-- col-lg-4 -->

				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-globe" style="font-size:48px;color:blue"></i>
					<i class="fas fa-gamepad fa-7x"></i>
					<h2>Otimizado para Voz sobre IP e jogos on-line</h2>
					<p>Baixa latência para aplicações de Voz sobre IP e jogos on-line </p>
				</div><!-- col-lg-4 -->

				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-globe" style="font-size:48px;color:blue"></i>
					<i class="fas fa-globe-americas fa-7x"></i>
					<h2>Área de Cobertura</h2>
					<p>Bairro Imperial de São Cristóvão e Adjacências </p>
				</div><!-- col-lg-4 -->


			</div><!-- row -->

			<div class="row">
				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-signal" style="font-size:48px;color:blue"></i>
					<i class="fas fa-home fa-7x"></i>
					<h2>Planos residenciais</h2>
					<p> Oferecemos banda larga para a sua residência. </p>
				</div><!-- col-lg-4 -->

				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-phone" style="font-size:48px;color:blue"></i>
					<i class="fas fa-building fa-7x"></i>
					<h2>Planos Empresariais</h2>
					<p>Oferecemos links dedicados no perfil ideal para os seus negócios </p>
				</div><!-- col-lg-4 -->

				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-globe" style="font-size:48px;color:blue"></i>
					<i class="fas fa-flag fa-7x"></i>
					<h2>Link para eventos</h2>
					<p>Oferecemos link para eventos, garantindo a melhor performance </p>
				</div><!-- col-lg-4 -->

				<div class="col-lg-3 callout">
					<i class="glyphicon glyphicon-globe" style="font-size:48px;color:blue"></i>
					<i class="fas fa-headphones fa-7x"></i>
					<h2>Suporte técnico grátis</h2>
					<p>Atendemos e agendamos visita técnica sem custo adicional </p>
				</div><!-- col-lg-4 -->


			</div>
		</div>
	</div>
</div>