<div class="container-fluid contein">

    <div class="row rowt">
        <div role="banner" class=" row col-lg-12 toolbar">
            <div class="row col-6 col-lg-2 col-md-3 col-sm-4 col-xs-2"><img width="200" height="100" alt="JL Logo"
                    src="../../assets/img/logo5.png"></div>
            <div class="col-6 col-lg-10">
                <div class="row col-12 col-lg-12 col-sm-4 col-xs-6">
                    <div class="clearfix grpelem">
                        <p class="p-class">Suporte e Financeiro:</p>
                        <p class="p-class"><span class="u125-3">21</span><span id="u125-4"></span><span class="u125-5">
                                3890 1846</span></p>
                        <p class="u125-8">comercial@jltelecom.net</p>
                        <p class="u125-8">seg. a sex.: das 8hrs às 20hrs</p>
                        <p class="u125-10">sáb. dom. e feriados: 9hrs às 13hrs</p>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <nav class="nav-ali navbar navbar-dark navbar-expand-lg">
                        
                        <button type="button"  class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                            aria-expanded="false" aria-label="Alterna navegação">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav">
                                <li class="nav-item nav-jl"><a href="#home" class="nav-a nav-link">
                                        <i class="fa fa-home" aria-hidden="true"></i> HOME </a></li>
                                <li class="nav-item nav-jl"><a href="#sobre" class="nav-a nav-link"><i
                                            aria-hidden="true" class="fa fa-university"></i> QUEM SOMOS</a></li>
                                <li class="nav-item nav-jl"><a href="#planos" class="nav-a nav-link"><i
                                            class="fa-donate fas"></i> PLANOS</a></li>
                                <li class="nav-item nav-jl"><a href="#contato" class="nav-a nav-link"><i
                                            class="fa-phone-alt fas"></i> FALE CONOSCO</a></li>
                                <li class="nav-item nav-jl"><a href="http://painel.jltelecom.net:20253/central"
                                        target="_blank" class="nav-a nav-link"><i class="fa-user fas"></i> ÁREA DO
                                        CLIENTE</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>





    <div role="main">
        <app-pagina-inicial id="home" name="home"></app-pagina-inicial>

        <app-pagina-meio id="sobre" name="sobre"></app-pagina-meio>

        <app-pagina-planos id="planos" name="planos"></app-pagina-planos>

        <app-pagina-final id="contato" name="contato"></app-pagina-final>
    </div>



    <footer class="footer">
        <div class="container text-center text-md-left">
            <div class="row">
                <div class="col-lg-4 col-md-3 col-xl-3 mb-4">
                    <h5 class="font-weight-bold text-uppercase">JL TELECOM</h5>
                    <hr class="accent-3 d-inline-block mb-4 mt-0 mx-auto teal" style="width:60px">
                    <p class="texto-jl"> Somos uma empresa 100% brasileira e nosso objetivo principal é proporcionar o
                        avanço do acesso à internet banda larga para todos em nossa área de atuação. Fale conosco:</p>
                    <p class="texto-jl-contato"><i class="fa-phone-volume fas"></i> (21) 3890-1846 </p>
                    <p class="texto-jl-contato"><i class="fa-envelope far"></i> comercial@jltelecom.net </p>
                </div>
                <hr class="clearfix d-md-none w-100">
                <div class="col-lg-4 col-md-3 col-xl-3 mb-4">
                    <h5 class="font-weight-bold text-uppercase">INTERNET BANDA LARGA</h5>
                    <hr class="accent-3 d-inline-block mb-4 mt-0 mx-auto teal" style="width:60px">
                    <p class="texto-jl-contato"><img src="../../assets/img/no-phone.jpg" alt="" style="width:30px"> Não
                        ocupa e nem utiliza a sua linha telefônica Navegue muito mais rápido.</p>
                    <p class="texto-jl-contato"><img src="../../assets/img/24h.jpg" alt="" style="width:30px"> Conexão
                        24h por dia, 7 dias por semana, Você conectado o tempo todo,Sem sustos no final do mês.</p>
                </div>
                <hr class="clearfix d-md-none w-100">
                <div class="col-lg-4 col-md-3 col-xl-3 mb-4">
                    <h5 class="font-weight-bold text-uppercase"> </h5>
                    <hr class="accent-3 d-inline-block mb-4 mt-0 mx-auto teal" style="width:60px">
                                  </div>
                <hr class="clearfix d-md-none w-100">
                <div class="col-lg-4 col-md-3 col-xl-3 mb-4">
                    <h5 class="font-weight-bold text-uppercase"></h5>
                    <hr class="accent-3 d-inline-block mb-4 mt-0 mx-auto teal" style="width:60px">
                                    </div>
            </div>
        </div>
    </footer>

</div>