import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { PrincipalComponent } from './principal/principal.component';
import { PaginaInicialComponent } from './principal/pagina-inicial/pagina-inicial.component';
import { PaginaMeioComponent } from './principal/pagina-meio/pagina-meio.component';
import { PaginaFinalComponent } from './principal/pagina-final/pagina-final.component';
import {CardModule} from 'primeng/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PaginaPlanosComponent } from './principal/pagina-planos/pagina-planos.component';
import {GMapModule} from 'primeng/gmap';

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    PaginaInicialComponent,
    PaginaMeioComponent,
    PaginaFinalComponent,
    PaginaPlanosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,    
    CardModule,
    BrowserAnimationsModule,
    GMapModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
